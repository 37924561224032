.login-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background: #1D2833;
  overflow: hidden;
}

.login-page .login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 360px;
  position: relative;
  z-index: 99;
}
.login-page .login-area .login-form .login-title {
  color: #fff !important;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}
.login-title .logoLogin {
  width: 150px;
}
.login-page .login-area .login-form h1 {
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
  font-size: 24px;
}
.login-page .login-area .login-form p {
  opacity: 0.7;
  margin-bottom: 20px;
  color: white !important;
}
.login-page .login-area .login-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background: #d82e35;
  background: linear-gradient(0deg, #d82e35 0%, #80181c 100%);
  background-size: 200% 200%;
  -webkit-animation: Animation 15s ease infinite;
  -moz-animation: Animation 15s ease infinite;
  animation: Animation 15s ease infinite;
  border-radius: 18px;
  padding: 50px 0px;
  position: relative;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%); */
}

/* .login-page .login-area .login-form .login-title {
  color: #fff !important;
  text-align: center;
  font-size: 30px;
} */

.login-page .login-form .login-field {
  width: 100%;
  margin-bottom: 40px;
}

.login-page .login-form .validator-form {
  width: 100%;
  padding: 0 30px;
  text-align: center;
}
.login-page .login-form .MuiInputBase-root {
  color: #fff !important;
}
.login-page .login-form .validator-form label {
  color: #fff !important;
}

.login-page .login-form .validator-form .MuiFormHelperText-root.Mui-error {
  color: #fff !important;
}

.login-page .login-form .validator-form .MuiInput-underline:before,
.login-page .login-form .validator-form .MuiInput-underline:after {
  border-bottom: 1px solid #fff !important;
}

.login-page input:-webkit-autofill,
.login-page input:-webkit-autofill:hover,
.login-page input:-webkit-autofill:focus,
.login-page input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #fff !important;
}

.login-page .login-area .login-form .validator-form .login-btn {
  width: 100%;
  border: none;
  padding: 14px 20px;
  color: #000 !important;
  font-size: 16px;
  min-height: 40px;
  line-height: 30px;
  font-weight: 700;
  border-radius: 6px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: unset !important;
}
.MuiButton-label .xdc{
  margin-left: 10px;
}
.login-page .login-area .login-page-logo {
  width: 70%;
  padding: 60px 70px;
  border-radius: 20px;
  border: 2px solid #d82e35;
  background: #d82e35;
  box-shadow: rgba(242, 16, 16, 0.25) 0px 50px 100px -20px,
    rgba(175, 40, 40, 0.473) 0px 30px 60px -30px,
    rgba(245, 37, 37, 0.459) 0px -2px 6px 0px inset;
}

/* ========== Responsive =========== */

@media screen and (max-width: 425) {
  .login-page .login-area {
    margin-top: 15% !important;
  }
}
.bgLoginPg {
  background-image: url("/images/bgLogin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.09;
  inset: 7px 4px 0px 0px;
  flex: 0 0 auto;
  mix-blend-mode: lighten;
  overflow: visible;
  z-index: 1;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  height: 1590px;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  width: 1736px;
}
.bgDashboard {
  background-image: url("/images/bgLogin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.09;
  inset: 7px 4px 0px 0px;
  flex: 0 0 auto;
  mix-blend-mode: lighten;
  overflow: visible;
  z-index: 1;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  height: 1590px;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  width: 100%;
}