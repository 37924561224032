.collection-wrap{
  display: flex;
  flex-wrap: wrap;
  margin: 30px -15px -15px
}

.collection-wrap h2{
  color: #39343A !important;
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
}

.collection-wrap .box{
  width: calc(25% - 30px);
  margin: 15px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 15px 20px;
  text-align: center;
}

.collection-wrap .box img{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.collection-wrap h3{
  color: #39343A;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 3px;
}

.collection-wrap p{
  color: #666666 !important;
  font-size: 14px;
}

.profile .tab-content .signin {
  max-width: 100%;
  text-align: left;
}
.profile .tab-content .create .MuiFormControl-fullWidth{
  max-width: 690px;
  border-radius: 8px;
}
.profile .tab-content .create .MuiFormControl-fullWidth .MuiInputBase-colorPrimary{
  background: #fff;
  padding: 0;
  font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    border-radius: 8px;
    height: auto !important;
    margin-top: 0 !important;
}
.profile .tab-content .create .MuiFormControl-fullWidth .MuiInputBase-colorPrimary .MuiSelect-select{
  font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    padding: 13px 20px;
}
.profile .tab-content .create h2:first-child {
  margin-bottom: 50px;
}

.profile .tab-content .create h2 {
  margin-bottom: 12px;
  font-size: 40px;
  color: #39343A;
}

.profile .tab-content .create p {
  margin-bottom: 24px;
  color: #666666;
}

.profile .tab-content .create .form-group {
  margin-bottom: 48px;
}

.profile .tab-content .create .form-group>span {
  color: #666666;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.profile .tab-content .create .form-group input,
.profile .tab-content .create .form-group select,
.profile .tab-content .create .form-group textarea,
.profile .tab-content .create .form-group .upload-file {

  max-width: 690px;
}

form p a {
  color: #28B467;
}

.profile .tab-content .create .choose {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.profile .tab-content .create .choose .box {
  width: 49%;
  display: flex;
  align-items: center;
  padding: 24px;
  background: #fff;
  border-radius: 8px;
}

.profile .tab-content .create .choose .box.active {
  background: #343444;
}

.profile .tab-content .create .choose .box img {
  width: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.profile .tab-content .create .choose .box .info {
  width: calc(100% - 120px);
  padding-left: 32px;
}

.profile .tab-content .create .choose .box .info h3 {
  font-size: 20px;
  font-weight: 700;
  color: #39343A;
  margin-bottom: 16px;
}

.profile .tab-content .create .choose .box .info p {
  margin-bottom: 0;
  color: #666666;
  font-size: 14px;
}

.profile .tab-content .create .common-btn.create-btn {
  background: #28B467;
  width: 183px;
  height: 54px;
  border-radius: 100px;
}

.profile .tab-content .create {
  margin-bottom: 30px;
}

.profile .tab-content .create .check-box {
  width: 52px;
  height: 26px;
  background: #EBEBEB;
  border-radius: 50px;
}

.profile .tab-content .create .check-box input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 52px;
  height: 26px;
  background-color: #39343A !important;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
  margin: 0 !important;
  border: 0 !important;
}

.profile .tab-content .create input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 4px;
  left: 6px;
  background: #28B467;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.profile .tab-content .create input:checked[type="checkbox"]::after {
  left: 50%;
}

.profile .tab-content .create .form-group .label-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  max-width: 690px;
}

.profile .tab-content .create .form-group .label-head label {
  display: flex;
  align-items: center;
  margin: 0;
}

.upload-file {
  position: relative;
  border-radius: 8px;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.upload-file input {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  opacity: 0;
  top: 0;
}

.upload-file span {
  color: #666666;
  font-size: 14px;
  line-height: 22px;
}

.profile .common-btn{
  background: #28B467;
  color: #fff;
  border-radius: 100px;
  padding: 15px 35px;
  border: 0;
}
.profile p img{
  display: inline;
}
.signin form input,
.signin form select,
.signin form textarea {
    border: 0 !important;
    border-radius: 8px;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    background-color: #fff !important;
    height: 48px;
    width: 100%;
    display: block;
}
form label{
  display: block;
  margin-bottom: 16px;
  font-weight: 600;
}
.signin form select {
    background-color: #fff !important;
}

.signin form select option {
    color: #666666 !important;
}

.signin form textarea {
    resize: none;
    height: 120px;
}

.signin form input::placeholder,
.signin form textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
}

.signin form .form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    padding: 0;
}

.signin form .form-check {
    max-width: 130px;
    width: 100%;
    margin-bottom: 33px;
}

.signin form .form-check label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 8px;
}
.collection-wrap .wallet-address{
  width: 100%;
}
.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap .wallet-address span, .collection-wrap .wallet-address p {
  color: #39343A;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  display: inline-block;
  text-overflow: ellipsis;
  width: calc(100% - 25px);
  overflow: hidden;
  white-space: nowrap;
}
.collection-wrap .wallet-address img{
  width: auto !important;
  height: auto !important;
  border-radius: 0 !important;
}

@media screen and (max-width: 1440px){
  .collection-wrap .box{
    width: calc(33.33% - 30px);
  }
}

@media screen and (max-width: 1100px){
  .collection-wrap .box{
    width: calc(50% - 30px);
  }

  .profile-wrapper .inner .inner-wrap {
    padding: 32px;
  }

  .profile-wrapper .inner .profile-bottom {
    margin-top: 0;
  }

  .profile-wrapper .inner .inner-wrap .right .content {
    padding-bottom: 20px;
    width: 100%;
  }

  .profile-wrapper .inner .inner-wrap .right {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px){
  .collection-wrap .box{
    width: calc(33.33% - 30px);
  }
}

@media screen and (max-width: 767px){
  .collection-wrap .box{
    width: calc(50% - 30px);
  }
}

@media screen and (max-width: 575px){
  .collection-wrap .box{
    width: 100%;
  }

  .profile-wrapper .inner .inner-wrap {
    padding: 20px;
    flex-direction: column;
  }

  .profile-wrapper .inner .inner-wrap .img-wrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-wrapper .nav-tabs li {
    width: calc(50% - 20px);
  }

  .profile-wrapper .inner .inner-wrap .right {
    padding-left: 0;
    width: 100%;
  }
}